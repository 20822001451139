<template>
  <div class="main" v-if="prerender">
    <div v-show="loading" class="loading">
      <span></span>
    </div>
    <div v-show="!loading" class="container" :class="brand">
      <img
        loading="lazy"
        v-if="brand === 'jeep-4xe'"
        src="@/assets/images/EmailVerification/jeep-4xe-logo-lockup.png"
        alt=""
      />
      <img loading="lazy" v-else src="@/assets/images/EmailVerification/LogoBolt.png" alt="" />
      <h1 v-if="verified">Thanks! Your account is verified.</h1>
      <h1 v-else>Oops! Something went wrong.</h1>
      <template v-if="verified">
        <p v-if="brand === 'jeep-4xe'">
          Feel free to close this page and continue using the Jeep 4xe Charging Network App.<br />
          You may need to sign in again.
        </p>
        <p v-else>
          Feel free to close this page and continue using the Electrify America app.<br />
          You may need to sign in again.
        </p>
      </template>
      <template v-else>
        <template v-if="brand === 'jeep-4xe'">
          <p>Please return to the Jeep 4xe Charging Network app and select “Send again” to retry.</p>
        </template>
        <template v-else>
          <p>Please return to the Electrify America app and select “Send again” to retry.</p>
        </template>
        <p>Still not working? Call our Support team at 1-833-632-2778.</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'email-validate-en',
  metaInfo: {
    title: 'Email Verification | Electrify America',
    meta: [{ name: 'robots', content: 'noindex' }],
  },
  created() {
    setTimeout(() => {
      const isSafari = window.safari !== undefined;
      const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isSafari && !iOS) {
        this.loading = false;
      } else {
        if (this.brand === 'jeep-4xe') {
          window.location = 'eajeepapp://validated_email';
        } else {
          window.location = 'eaapp://validated_email';
        }
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    }, 1000);
    const parameters = this.$route.query.success;
    if (parameters === 'true') {
      this.verified = !this.verified;
    }
  },
  data() {
    return {
      loading: true,
      verified: null,
      prerender: !window.PRERENDER_INJECTED,
    };
  },
  computed: {
    brand() {
      switch (this.$route.name) {
        case 'email-validate-jeep-4xe-en':
          return 'jeep-4xe';
        default:
          return 'ea';
      }
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    userEmail() {
      return this.$store.state.email;
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  min-height: 680px;
}

.loading {
  @keyframes loading {
    to {
      transform: rotateZ(360deg);
    }
  }
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: block;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: $c-primary;
    border-bottom-color: $c-primary;
    animation: loading 850ms ease-in-out infinite;
  }
}

.container {
  padding: 80px 20px 0;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

img {
  width: 43px;
  height: auto;
}

h1 {
  margin: 32px 0 24px;
  font-size: 48px;
  font-weight: $fw-medium;
}

// Brand styles
.jeep-4xe {
  img {
    width: 242px;
  }
}
</style>
